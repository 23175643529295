@import "constants";

#landing-section-scroll-top {
  height: 1px;
}

#landing-section {
  margin-top: 208px;
}

#body-line-1 {
  top: 200px;
  left: $body-line-left;
  height: 1310px;
  width: $body-line-size;
}

#body-line-box-1 {
  width: 480px;
  height: 400px;
  border-top: $body-line-size solid $gold;
  border-right: $body-line-size solid $gold;
  right: 140px;
  top: 70px;
}

#landing-title {
  font-family: $header-font;
  font-weight: normal;
  font-size: 80px;
  line-height: 99px;
  color: $gold;

  span {
    color: white;
  }
}

#landing-below-title {
  color: white;
  font-family: $body-font;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  max-width: 650px;
  margin-top: 2px;
}

#two-image-split {
  display: flex;
  justify-content: space-between;

  .example-picture {
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    &::after {
      position: absolute;
      top: 10px;
      right: 10px;
      display: block;
      content: attr(data-genesis-tag);
      color: white;
      font-family: $mono-font;
      font-size: 12px;
      line-height: 14px;
      width: 20px;
      word-break: break-all;
      text-align: right;
    }
  }
}

#countdown-btn {
  background-color: $gold;
  height: 70px;
  width: 400px;
  font-size: 22px;
  margin: 80px 0 102px;
  transition: $animation-time background-color, $animation-time color;

  &:hover {
    background-color: white;
    color: $black;
  }
}

#abstract-example-pic {
  margin-top: 54px;
}

@media (max-width: $mobile-screen-width) {
  #landing-section {
    padding: 0 $mobile-content-horizontal-padding;
    margin-top: 100px;
  }

  #body-line-1,
  #body-line-box-1 {
    display: none;
  }

  #landing-title {
    font-size: 48px;
    line-height: 60px;
  }

  #landing-below-title {
    font-size: 16px;
    line-height: 22px;
  }

  #countdown-btn {
    font-size: 14px;
    height: 50px;
    width: 250px;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  #two-image-split {
    justify-content: flex-start;
    overflow: hidden;
    margin-right: -20px;

    .example-picture,
    .example-picture img {
      width: 300px;
      height: 300px;
    }

    .example-picture::after {
      top: 8px;
      right: 8px;
      font-size: 10px;
      line-height: 12px;
      width: 14px;
    }
  }

  #abstract-example-pic {
    margin-top: 130px;
    margin-left: 10px;
  }
}
