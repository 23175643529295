#landing-section-scroll-top {
  height: 1px;
}

#landing-section {
  margin-top: 208px;
}

#body-line-1 {
  height: 1310px;
  width: 3px;
  top: 200px;
  left: 180px;
}

#body-line-box-1 {
  width: 480px;
  height: 400px;
  border-top: 3px solid #9e7509;
  border-right: 3px solid #9e7509;
  top: 70px;
  right: 140px;
}

#landing-title {
  color: #9e7509;
  font-family: Righteous, sans-serif;
  font-size: 80px;
  font-weight: normal;
  line-height: 99px;
}

#landing-title span {
  color: #fff;
}

#landing-below-title {
  color: #fff;
  max-width: 650px;
  margin-top: 2px;
  font-family: Oxygen, sans-serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 36px;
}

#two-image-split {
  justify-content: space-between;
  display: flex;
}

#two-image-split .example-picture {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

#two-image-split .example-picture:after {
  content: attr(data-genesis-tag);
  color: #fff;
  width: 20px;
  word-break: break-all;
  text-align: right;
  font-family: Roboto Mono, monospace;
  font-size: 12px;
  line-height: 14px;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
}

#countdown-btn {
  height: 70px;
  width: 400px;
  background-color: #9e7509;
  margin: 80px 0 102px;
  font-size: 22px;
  transition: background-color .3s, color .3s;
}

#countdown-btn:hover {
  color: #000406;
  background-color: #fff;
}

#abstract-example-pic {
  margin-top: 54px;
}

@media (max-width: 1140px) {
  #landing-section {
    margin-top: 100px;
    padding: 0 20px;
  }

  #body-line-1, #body-line-box-1 {
    display: none;
  }

  #landing-title {
    font-size: 48px;
    line-height: 60px;
  }

  #landing-below-title {
    font-size: 16px;
    line-height: 22px;
  }

  #countdown-btn {
    height: 50px;
    width: 250px;
    margin-top: 30px;
    margin-bottom: 50px;
    font-size: 14px;
  }

  #two-image-split {
    justify-content: flex-start;
    margin-right: -20px;
    overflow: hidden;
  }

  #two-image-split .example-picture, #two-image-split .example-picture img {
    width: 300px;
    height: 300px;
  }

  #two-image-split .example-picture:after {
    width: 14px;
    font-size: 10px;
    line-height: 12px;
    top: 8px;
    right: 8px;
  }

  #abstract-example-pic {
    margin-top: 130px;
    margin-left: 10px;
  }
}

/*# sourceMappingURL=index.6cc1ab26.css.map */
